// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div>
    <a-spin :spinning="fetchLoading">
      <a-button
        v-if="'registerUserKeys' in $store.getters.apis && 'listRolePermissions' in $store.getters.apis"
        type="dashed"
        style="width: 100%; margin-bottom: 15px"
        @click="onShowAddKeyPair()">
        <template #icon><plus-outlined /></template>
        {{ $t('label.register.api.key') }}
      </a-button>
      <a-button
        v-if="this.selectedRowKeys.length > 0 && ('deleteUserKeys' in $store.getters.apis)"
        type="primary"
        danger
        style="width: 100%; margin-bottom: 15px"
        @click="bulkActionConfirmation()">
        <template #icon><delete-outlined /></template>
        {{ $t('label.action.bulk.delete.api.keys') }}
      </a-button>
      <a-table
        size="small"
        style="overflow-y: auto"
        :columns="columns"
        :dataSource="keypairs"
        :rowKey="item => item.id"
        :key="item => item.id"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :pagination="false" >
        <template #id="{ record }">
          <strong>
            <tooltip-button
              tooltipPlacement="right"
              :tooltip="$t('label.copy')"
              icon="CopyOutlined"
              type="dashed"
              size="small"
              @onClick="$message.success($t('label.copied.clipboard'))"
              :copyResource="record.id" />
          </strong>
          <div>
            <router-link :to="{ path: '/keypair/' + record.id }" >
              {{ record.id.substring(0, 20) }}...
            </router-link>
          </div>
        </template>

        <template #apikey="{ record }">
          <strong>
            <tooltip-button
              tooltipPlacement="right"
              :tooltip="$t('label.copy')"
              icon="CopyOutlined"
              type="dashed"
              size="small"
              @onClick="$message.success($t('label.copied.clipboard'))"
              :copyResource="record.apikey" />
          </strong>
          <div>
            {{ record.apikey.substring(0, 20) }}...
          </div>
        </template>

        <template #secretkey="{ record }">
          <strong>
            <tooltip-button
              tooltipPlacement="right"
              :tooltip="$t('label.copy')"
              icon="CopyOutlined"
              type="dashed"
              size="small"
              @onClick="$message.success($t('label.copied.clipboard'))"
              :copyResource="record.secretkey" />
          </strong>
          <div>
            {{ record.secretkey.substring(0, 20) }}...
          </div>
        </template>

        <template #name="{ record }">
          <div> {{record.name}} </div>
        </template>

        <template #startdate="{ record }">
          <div> {{ $toLocaleDate(record.startdate) }} </div>
        </template>

        <template #enddate="{ record }">
          <div> {{ $toLocaleDate(record.enddate)}} </div>
        </template>

        <template #created="{ record }">
          <div> {{ $toLocaleDate(record.created) }} </div>
        </template>

      </a-table>
      <a-divider/>
      <a-pagination
        class="row-element pagination"
        size="small"
        :current="page"
        :pageSize="pageSize"
        :total="totalKeypairs"
        :showTotal="total => `${$t('label.total')} ${total} ${$t('label.items')}`"
        :pageSizeOptions="['10', '20', '40', '80', '100']"
        @change="changePage"
        @showSizeChange="changePageSize"
        showSizeChanger>
        <template #buildOptionText="props">
          <span>{{ props.value }} / {{ $t('label.page') }}</span>
        </template>
      </a-pagination>
    </a-spin>
    <bulk-action-view
      v-if="(showConfirmationAction || showGroupActionModal)"
      :showConfirmationAction="showConfirmationAction"
      :showGroupActionModal="showGroupActionModal"
      :items="keypairs"
      :selectedRowKeys="selectedRowKeys"
      :selectedItems="selectedItems"
      :columns="columns"
      :selectedColumns="selectedColumns"
      action="eraseKeypairs"
      :loading="loading"
      :message="bulkDeleteMessage"
      @group-action="eraseKeypairs"
      @handle-cancel="handleCancelBulk"
      @close-modal="closeModalBulk" />
    <generate-api-key-pair
      :showAddKeyPair="showAddKeyPair"
      :resource="resource"
      @fetch-data="fetchData"
      @handle-cancel="handleCancelAddKeyPair"
      @refresh-data="handleRefreshData"
      @close-modal="closeModalAddKeyPair" />
  </div>
</template>
<script>
import { api } from '@/api'
import Status from '@/components/widgets/Status'
import TooltipButton from '@/components/widgets/TooltipButton'
import BulkActionView from '@/components/view/BulkActionView.vue'
import eventBus from '@/config/eventBus'
import OwnershipSelection from '@/views/compute/wizard/OwnershipSelection.vue'
import GenerateApiKeyPair from '@/views/iam/GenerateApiKeyPair.vue'
import store from '@/store'

export default {
  name: 'KeypairsTab',
  components: {
    OwnershipSelection,
    Status,
    TooltipButton,
    BulkActionView,
    GenerateApiKeyPair,
    store
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fetchLoading: false,
      keypairs: [],
      page: 1,
      pageSize: 10,
      totalKeypairs: 0,
      selectedRowKeys: [],
      selectedItems: [],
      selectedColumns: [],
      filterColumns: ['Action'],
      showConfirmationAction: false,
      showAddKeyPair: false,
      showGroupActionModal: false,
      bulkDeleteMessage: {
        title: this.$t('label.action.bulk.delete.api.keys'),
        confirmMessage: this.$t('label.confirm.delete.api.keys')
      },
      columns: [
        {
          title: this.$t('label.id'),
          dataIndex: 'id',
          slots: { customRender: 'id' }
        },
        {
          title: this.$t('label.apikey'),
          dataIndex: 'apikey',
          slots: { customRender: 'apikey' }
        },
        {
          title: this.$t('label.secretkey'),
          dataIndex: 'secretkey',
          slots: { customRender: 'secretkey' }
        },
        {
          title: this.$t('label.name'),
          dataIndex: 'name',
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.start.date'),
          dataIndex: 'startdate',
          slots: { customRender: 'startdate' }
        },
        {
          title: this.$t('label.end.date'),
          dataIndex: 'enddate',
          slots: { customRender: 'enddate' }
        },
        {
          title: this.$t('label.created'),
          dataIndex: 'created',
          slots: { customRender: 'created' }
        }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    resource: {
      deep: true,
      handler (newItem) {
        if (!newItem || !newItem.id) {
          return
        }
        this.fetchData()
      }
    }
  },
  inject: ['parentFetchData'],
  methods: {
    fetchData () {
      const params = {
        listall: true,
        page: this.page,
        pagesize: this.pageSize,
        userid: this.resource.id
      }
      this.fetchLoading = true
      api('listUserKeys', params).then(json => {
        this.totalKeypairs = json.listuserkeysresponse.count || 0
        this.keypairs = json.listuserkeysresponse.userapikey || []
      }).finally(() => {
        this.fetchLoading = false
      })
    },
    setSelection (selection) {
      this.selectedRowKeys = selection
      this.$emit('selection-change', this.selectedRowKeys)
      this.selectedItems = (this.keypairs.filter(function (item) {
        return selection.indexOf(item.id) !== -1
      }))
    },
    resetSelection () {
      this.setSelection([])
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.setSelection(selectedRowKeys)
    },
    changePage (page, pageSize) {
      this.page = page
      this.pageSize = pageSize
      this.fetchData()
    },
    changePageSize (currentPage, pageSize) {
      this.page = currentPage
      this.pageSize = pageSize
      this.fetchData()
    },
    onShowAddKeyPair () {
      this.showAddKeyPair = true
    },
    eraseKeypairs () {
      this.selectedColumns.splice(0, 0, {
        dataIndex: 'status',
        title: this.$t('label.operation.status'),
        slots: { customRender: 'status' },
        filters: [
          { text: 'In Progress', value: 'InProgress' },
          { text: 'Success', value: 'success' },
          { text: 'Failed', value: 'failed' }
        ]
      })
      if (this.selectedRowKeys.length > 0) {
        this.showGroupActionModal = true
      }
      this.deleteKeypairs(this.selectedItems)
    },
    deleteKeypairs (keypairs) {
      var responses = []
      this.fetchLoading = true
      for (const keypair of keypairs) {
        api('deleteUserKeys', {
          id: keypair.id
        }).then(response => {
          response.keyid = keypair.id
          responses.push(response)
          const jobId = response.deleteuserkeysresponse.jobid
          eventBus.emit('update-job-details', { jobId, resourceId: null })
        }).catch(error => {
          this.fetchLoading = false
          this.$notification.error({
            message: `${this.$t('label.error')} ${error.response.status}`,
            description: error.response.data.errorresponse.errortext,
            duration: 0
          })
        })
      }
    },
    bulkActionConfirmation () {
      this.showConfirmationAction = true
      this.selectedColumns = this.columns.filter(column => {
        return !this.filterColumns.includes(column.title)
      })
      this.selectedItems = this.selectedItems.map(v => ({ ...v, status: 'InProgress' }))
    },
    handleCancelBulk () {
      eventBus.emit('update-bulk-job-status', { items: this.selectedItems, action: false })
      this.showGroupActionModal = false
      this.selectedItems = []
      this.selectedColumns = []
      this.selectedRowKeys = []
      this.parentFetchData()
    },
    closeModalBulk () {
      this.showConfirmationAction = false
    },
    closeModalAddKeyPair () {
      this.showAddKeyPair = false
    },
    handleCancelAddKeyPair () {
      this.showAddKeyPair = false
    },
    handleRefreshData () {
      this.$emit('refresh-data')
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
    max-height: 95vh;
    width: 95vw;
    overflow-y: scroll;
    margin: -24px;

    @media (min-width: 1000px) {
      max-height: 70vh;
      width: 900px;
    }

    &__header,
    &__footer {
      padding: 20px;
    }

    &__header {
      display: flex;

      .ant-select {
        min-width: 200px;
      }

      &__col {

        &:not(:last-child) {
          margin-right: 20px;
        }

        &--full {
          flex: 1;
        }

      }

    }

    &__footer {
      display: flex;
      justify-content: flex-end;

      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    &__item {
      padding-right: 20px;
      padding-left: 20px;

      &--selected {
        background-color: #e6f7ff;
      }

    }

    &__title {
      font-weight: bold;
    }

    &__outer-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      cursor: pointer;

      @media (min-width: 760px) {
        flex-direction: row;
        align-items: center;
      }

    }

    &__row {
      margin-bottom: 10px;

      @media (min-width: 760px) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    &__radio {
      display: flex;
      justify-content: flex-end;
    }

  }
</style>
